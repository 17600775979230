import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'gatsby';

export default () => (
  <Layout>
    <section class='hero is-light is-fullheight'>
      <div class='hero-body'>
        <div class='container'>
          <h1 class='title'>Merci d'avoir pris contact avec nous !</h1>
          <h2 class='subtitle'>Nous reviendrons vers vous dès que possible.</h2>
          <Link className='button' to='/'>
            Retourner sur la page d'accueil
          </Link>
        </div>
      </div>
    </section>
  </Layout>
);
